<template>
  <div class="tubs-content__item">
    <div class="code large">
      <div class="hidden">
        <textarea readonly id="htttpsCopyLink" v-model="link" />
        <textarea readonly id="htttpsCopyCode" v-model="platformCode" />
      </div>
      <router-link
        v-if="platform.site"
        class="btn-settings _has-icon lower-link"
        exact
        :to="`/platform-settings/${platform.id}`"
      >
        <svg v-svg symbol="settings-2" size="0 0 19 19" role="info" />
      </router-link>
      <button type="button" class="btn _has-icon lower-link" @click="copy">
        <span class="icon-copy">
          <svg v-svg symbol="copy" size="0 0 13 15" role="info" /> </span
        >{{ $t("copy") }}
      </button>
      <code v-html="platform.site ? code : link" class="code__content"> </code>
    </div>
        <notifications group="message" />
  </div>
</template>
<script>
import subaccsService from "@/api/subaccsService";

export default {
  name: "Code",
  props: {
    platform: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      code: `&lt;script type="text/javascript"<br />
        src="https://${this.domain}/process.js?id=${this.platform.id}&p1=sub1&p2=sub2&p3=sub3&p4=sub4"<br />
        async&gt;&lt;/script&gt;`,
      link: `https://${this.domain}/?id=${this.platform.id}&p1=sub1&p2=sub2&p3=sub3&p4=sub4`,
      domain: "",
    };
  },
  mounted() {
    subaccsService.getLastDomain().then((resp) => {
      this.domain = resp.data.domain;
      this.code = `&lt;script type="text/javascript"<br />
        src="https://${this.domain}/process.js?id=${this.platform.id}&p1=sub1&p2=sub2&p3=sub3&p4=sub4"<br />
        async&gt;&lt;/script&gt;`;
      this.link = `https://${this.domain}/?id=${this.platform.id}&p1=sub1&p2=sub2&p3=sub3&p4=sub4`;
    });
  },
  computed: {
    platformCode() {
      return (
        '<script type="text/javascript"' +
        `src="https://${this.domain}/process.js?id=${this.platform.id}&p1=sub1&p2=sub2&p3=sub3&p4=sub4"` +
        `async>` +
        `<` +
        `/` +
        `script>`
      );
    },
  },
  methods: {
    copy() {
      if (this.platform.site) {
        this.$helper.copyCode("htttpsCopyCode");
      } else {
        this.$helper.copyCode("htttpsCopyLink");
      }
      this.$notify({
        group: "message",
        title: this.$t("copied_succ"),
        ignoreDuplicates: true,
      });
    },
  },
};
</script>